/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */

@import "./scss/includes";

html,
body {
  height: 100%;
}

app-root {
  display: block;
  height: 100%;
}

body {
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  color: $gray-dark;
  background-color: $background-grey;
  padding: 0;
  margin: 0;
}

/* WALKME ADAPTATION FOR HEADER V3 */
.cmx-nav-header__help-menu-item {
  .walkme-launcher-container {
    float: none !important;
    display: inline-block !important;
    height: 1rem !important;
  }
  .walkme-custom-launcher-outer-div {
    background-color: transparent !important;
    color: $bright-blue;
    left: auto !important;
    top: auto !important;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 14px !important;
    font-weight: 500;
    font-family: "Roboto";
    &:hover {
      color: $bright-blue-dark !important;
      font-weight: 500;
      font-family: "Roboto";
    }
  }
}

.select-jobsites {
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 500;
  color: $gray-dark;
}

/*Notifications bell styles */
#notificationDetailDialogContent {
  .cmx-notifications__modal-detail-heading {
    h2 {
    margin-bottom: 12px;
    }
    h2, p {
      text-align: left;
    }
    div {
      #functions, #jobsite {
        text-align: left;
        padding: 20px 0 12px 0;
        font-weight: bold;
      }
      & table:nth-child(1n) {
        width: 100%;
        thead {
          tr {
            th:first-child {
              padding-left: 20px;
              width: 70%;
            }
            th {
              font-size: 0.875rem;
              text-align: left;
              padding-bottom: 12px;
            }
          }
        }
      //Table content
       tbody {
        tr {
          td:first-child {
            padding-left: 20px;
            width: 70%;
          }
        }
        tr:nth-child(odd) {
          background-color: #eee;
          td {
            text-align: left;
            width: 30%;
          }
        }
        tr:nth-child(even) {
          background-color: #fff;
          td {
            text-align: left;
          }
        }
       }
      }
    }
    //Buttons reject and confirm request
    table {
      a[title~='support'] {
        color: #0074d4;
        border: 2px solid #0074d4;
        background-color: #fff;

       }
       a[title~='regular'] {
        color: #fff;
        background-color: #0074d4;

       }
       a[title~='support'], a[title~='regular'] {
        border-radius: 60px;
        padding: 5px 30px 5px 30px;
        margin-top: 25px;
        font-size: 0.875rem;
        text-decoration: none;
        display: inline-block;
       }
      &:nth-child(4n + 1) {
        th {
          @media (min-width: 360px) and (max-width: 700px) {
              width: 100%!important;
              text-align: center!important;
              display: block!important;
              font-size: -9rem!important;
              overflow-y: hidden!important;
          }
          &:first-child {
            width: 75%;
            text-align: right;
          }
          &:last-child {
            width: 25%;
            text-align: right;
          }
        }
      }
    }
  }
  & > table {
    table {
      &:last-child {
        width: 100%;
      }
    }
 }
}

.fade-in {
	-webkit-animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

